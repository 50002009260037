import { Link } from 'react-router-dom';
import styles from './apply.module.sass'

function Apply() {

    return (
      <div className={styles.apply}>
        <div className={styles.applyInner}>
          <Link to="/apply">Apply now</Link>
          <p>to be part of DAO ecosystem and elevate your impact.</p>
        </div>
      </div>
    );
  }
  
  export default Apply;