import { useState } from 'react';
import styles from './projects.module.sass';
import projectLogo from '../../../images/project_logo.webp';
import { Link } from 'react-router-dom';
import { Logo } from '../../../icons/icons';

const searchIcon = <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.58464 18.1995C13.9569 18.1995 17.5013 14.6551 17.5013 10.2829C17.5013 5.91062 13.9569 2.36621 9.58464 2.36621C5.21238 2.36621 1.66797 5.91062 1.66797 10.2829C1.66797 14.6551 5.21238 18.1995 9.58464 18.1995Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.4" d="M18.3346 19.0329L16.668 17.3662" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


function Projects({loading, data}) {
  const [searchTerm, setSearchTerm] = useState('');

  // const data = [
  //   { id: 1, name: 'PrivateAI', ath: 635.7, sale: 'N/A', raise: 666667, link: '/project/1' },
  //   { id: 2, name: 'GameFi', ath: 352.2, sale: 'N/A', raise: 95000, link: '/project/2' },
  //   { id: 3, name: 'Step.App', ath: 208.8, sale: 'N/A', raise: 100000, link: '/project/3' }
  // ];

  // Filter projects based on the search term
  const filteredData = data?.filter(item => 
    item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];
  

  return (
    <div className={styles.featured}>
      <div className={styles.innerHeader}>
        <h2>Projects</h2>
        <div className={styles.searchInner}>
          {searchIcon}
          <input
            type='text' 
            placeholder='Search' 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)} 
          />
        </div>
      </div>
      <div className={styles.inner}>
        <table border="1" style={{ width: '100%', textAlign: 'left' }}>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>ATH</th>
              <th>Public Sale TVL</th>
              <th>Raise</th>
              <th>Incubated</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={`/project/${item.id}`}>
                <td className={styles.logoTd}>
                  <Link to={`/project/${item.id}`}>
                    {item.logo ? 
                      <img className={styles.projectLogo} height={40} src={projectLogo} alt="project logo" />
                      :
                      <span className={styles.projectLogoPlaceholder}></span>
                    }
                  </Link>
                </td>
                <td className={styles.nameTd}>
                  <Link to={`/project/${item.id}`}>{item.name}</Link>
                </td>
                <td>
                  <Link to={`/project/${item.id}`}>{item.max_cap}</Link>
                </td>
                <td>
                  <Link to={`/project/${item.id}`}>{item.sale}</Link>
                </td>
                <td>
                  <Link to={`/project/${item.id}`}>${item.raise}</Link>
                </td>
                <td>
                  <Link to={`/project/${item.id}`}><Logo /></Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Projects;
