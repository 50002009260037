import { useState, useEffect } from "react";
import Footer from "../footer";
import Header from "../header/index";
import Sidebar from "../sidebar/sidebar";
import styles from "./Page.module.sass";

const Page = ({ children }) => {
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    // When the sidebar is open, prevent scrolling on the body
    if (sidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup to reset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [sidebar]);

  return (
    <>
      <div className={styles.page}>
        <Header setSidebar={setSidebar} />
        <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
        <div className={styles.inner}>
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Page;
