// src/Tabs.js
import React, { useState } from 'react';
import styles from './tabs.module.sass'

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className={styles.tabs}>
      <div className={styles.tabsHeader}>
        {React.Children.map(children, (child, index) => (
          <button
            className={`${styles.tabButton} ${activeTab === index ? styles.active : ''} ${ index === 1 ? styles.rightButton : styles.leftButton}`}
            onClick={() => handleTabClick(index)}
          >
            <img height={32} src={child.props.icon} alt="Chain icon" />
            <div className={styles.innerTexts}>
              <span>{child.props.label}</span>
              <p>{child.props.comment}</p>
            </div>
          </button>
        ))}
      </div>
      <div className={styles.tabInner}>
        {React.Children.map(children, (child, index) => {
          if (index === activeTab) return child.props.children;
        })}
      </div>
    </div>
  );
};

export default Tabs;
