import styles from './form.module.sass'

function NotConnected() {
    return (
      <div className={styles.formNot}>
        <h2>Please connect your wallet to continue</h2>
      </div>
    );
  }
  
  export default NotConnected;