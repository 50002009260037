import Form from "./form/form";
import Contact from "./form/contact"


function Apply() {
  return (
    <>
      <Contact />
      {/* <Form /> */}
    </>
  );
}

export default Apply;