import styles from "./Header.module.sass";

const accountIcon = (
    <svg width="18" height="18" viewBox="0 0 93 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clip-rule="evenodd" d="M46.2973 8.47303C25.6314 8.47303 8.87836 25.2261 8.87836 45.8919C8.87836 55.8129 12.7393 64.832 19.0405 71.5288C21.8049 66.1794 27.3876 62.5226 33.8243 62.5226H58.7702C65.207 62.5226 70.7897 66.1794 73.5541 71.5288C79.8553 64.832 83.7162 55.8129 83.7162 45.8919C83.7162 25.2261 66.9632 8.47303 46.2973 8.47303ZM66.8471 77.168C65.957 73.5342 62.6785 70.8379 58.7702 70.8379H33.8243C29.9161 70.8379 26.6376 73.5342 25.7474 77.168C31.6456 81.0512 38.7076 83.3109 46.2973 83.3109C53.887 83.3109 60.949 81.0512 66.8471 77.168ZM0.563049 45.8919C0.563049 20.6336 21.039 0.157715 46.2973 0.157715C71.5556 0.157715 92.0315 20.6336 92.0315 45.8919C92.0315 71.1503 71.5556 91.6262 46.2973 91.6262C21.039 91.6262 0.563049 71.1503 0.563049 45.8919ZM46.2973 25.1037C39.4086 25.1037 33.8243 30.688 33.8243 37.5766C33.8243 44.4653 39.4086 50.0496 46.2973 50.0496C53.1859 50.0496 58.7703 44.4653 58.7703 37.5766C58.7703 30.688 53.1859 25.1037 46.2973 25.1037ZM25.509 37.5766C25.509 26.0956 34.8162 16.7883 46.2973 16.7883C57.7783 16.7883 67.0856 26.0956 67.0856 37.5766C67.0856 49.0577 57.7783 58.3649 46.2973 58.3649C34.8162 58.3649 25.509 49.0577 25.509 37.5766Z" fill="#454545"/>
    </svg>
);

const hearthIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_856_23698)">
<path d="M18 7H21C21.2652 7 21.5196 7.10536 21.7071 7.29289C21.8946 7.48043 22 7.73478 22 8V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H18V7ZM4 9V19H20V9H4ZM4 5V7H16V5H4ZM15 13H18V15H15V13Z" fill="#F0F4F7"/>
</g>
<defs>
<clipPath id="clip0_856_23698">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
)
  

function Navigation({setSidebar}) {
  return (
      <div className={styles.navigation}>
        <button onClick={() => setSidebar(true)} href="/">{hearthIcon}</button>
      </div>
  );
}

export default Navigation;

