import slider from '../../../images/company_section_image.webp'
import styles from './hero.module.sass'
import {WwwIcon, GekoIcon, XIcon, TelegramIcon, DiscordIcon, MetroIcon, GithubIcon, LinkedinIcon, YoutubeIcon, RedditIcon, InstagramIcon} from '../../../icons/icons'

function Hero({ data, loading }) {
    return (
      <div className={styles.hero}>
          <div className={styles.overlay}></div>
          <img src={slider} alt="hero slider" />
          <div className={styles.heroContainer}>
            <div className={styles.heroInner}>
                {data && !loading ?
                  data.logo ?
                    <img src={data.logo} alt="project logo" /> :
                    <span className={styles.projectLogoPlaceholder}></span>
                  : null
                }
            </div>
            <h3>{data ? data.name : null}</h3>
            <div className={styles.innerLinks}>
                <a href="/"> <WwwIcon /></a>
                <a href="/"> <GekoIcon /></a>
                <a href="/"> <XIcon /></a>
                <a href="/"> <TelegramIcon /></a>
                <a href="/"> <DiscordIcon /></a>
                <a href="/"> <MetroIcon /></a>
            </div>
            <div className={styles.innerLinks}>
                <a href="/"> <GithubIcon /></a>
                <a href="/"> <LinkedinIcon /></a>
                <a href="/"> <YoutubeIcon /></a>
                <a href="/"> <InstagramIcon /></a>
                <a href="/"> <RedditIcon /></a>
            </div>
          </div>
      </div>
    );
  }
  
  export default Hero;



