import styles from './form.module.sass'

function Form() {
    return (
      <div className={styles.contact}>
        <p>To participate in Xxxxxxx DAO projects launchpad, please contact us:</p>
        <a href="mailto:info@xxxxxxx.io">info@xxxxxxx.io</a>
      </div>
    );
  }
  
  export default Form;