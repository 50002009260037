import React, { useEffect, useState } from "react";
import Apply from "./apply/apply";
import Featured from "./featured/featured";
import Hero from "./hero/hero";
import Projects from "./projects/projects";
import axios from "axios";

function Home() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/public/launchpad/`);
        setData(response.data.results);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [apiUrl]);

  return (
    <>
      <Hero />
      <Featured loading={loading} data={data} />
      <Projects loading={loading} data={data}  />
      <Apply />
    </>
  );
}

export default Home;
