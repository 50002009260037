import styles from './form.module.sass'

function Form() {
    return (
      <div className={styles.form}>
        <div className={styles.formInnerTwo}>
            <h2>Personal information</h2>
            <div className={styles.inputInner}>
                <span>First name</span>
                <input type="text" />
            </div>
            <div className={styles.inputInner}>
                <span>Last name</span>
                <input type="text" />
            </div>
            <div className={styles.inputInnerFull}>
                <span>Email address</span>
                <input type="email" />
            </div>
        </div>
        <div className={styles.buttonWrapper}>
            <button>Save</button>
        </div>
        <div className={styles.formInnerTwo}>
            <h2>KYC verification</h2>
            <p>KYC is not required to participate in offerings. You will be asked to complete it after your first participation.</p>
        </div>
      </div>
    );
  }
  
  export default Form;