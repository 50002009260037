import styles from './participations.module.sass'

function Participations() {
  return (
    <div className={styles.participations}>
        <span>
            You don't have any participations
        </span>
    </div>
  );
}

export default Participations;