// src/App.js
import React from 'react';
import Tabs from './Tabs';
import Tab from './Tab';
import bnbLogo from '../../../images/bnb_logo.png'
import ethLogo from '../../../images/eth_logo.png'
import Stake from './stake';

function Content() {
  return (
    <div className="App">
      <Tabs>
        <Tab label="Ethereum" comment="Generous yield" icon={ethLogo}>
          <Stake />
        </Tab>
        <Tab label="BNB Smart Chain" comment="No commitment." icon={bnbLogo}>
          <Stake />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Content;
