import slider from '../../../images/x-banner.webp'
import styles from './hero.module.sass'

const logo = <svg width="98" height="100" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.2545 44.1702H33.6674L22.7311 28.0613L11.9113 44.1702H1.32422L15.4016 23.7887L1.32422 3.46484H11.9113L22.7311 19.4583L33.6674 3.46484H44.2545L30.1189 23.7887L44.2545 44.1702Z" fill="white" fillOpacity="0.5"/>
<path d="M45.1529 44.8879H34.5658L23.6296 28.779L12.8097 44.8879H2.22266L16.3 24.5064L2.22266 4.18262H12.8097L23.6296 20.1761L34.5658 4.18262H45.1529L31.0174 24.5064L45.1529 44.8879Z" fill="white" fillOpacity="0.5"/>
<path d="M46.0553 45.6056H35.4682L24.5319 29.4968L13.7121 45.6056H3.125L17.2024 25.2242L3.125 4.90039H13.7121L24.5319 20.8938L35.4682 4.90039H46.0553L31.9197 25.2242L46.0553 45.6056Z" fill="white" fillOpacity="0.5"/>
<path d="M46.9537 46.3237H36.3666L25.4304 30.2147L14.6105 46.3237H4.02344L18.1008 25.942L4.02344 5.61816H14.6105L25.4304 21.6116L36.3666 5.61816H46.9537L32.8182 25.942L46.9537 46.3237Z" fill="white" fillOpacity="0.5"/>
<path d="M47.8521 47.0414H37.265L26.3288 30.9325L15.509 47.0414H4.92188L18.9992 26.6597L4.92188 6.33594H15.509L26.3288 22.3294L37.265 6.33594H47.8521L33.7166 26.6597L47.8521 47.0414Z" fill="white" fillOpacity="0.5"/>
<path d="M43.356 43.4524H32.7689L21.8327 27.3435L11.0129 43.4524H0.425781L14.5031 23.0709L0.425781 2.74707H11.0129L21.8327 18.7405L32.7689 2.74707H43.356L29.2204 23.0709L43.356 43.4524Z" fill="white"/>
</svg>

function Hero() {
    return (
      <div className={styles.hero}>
          <img src={slider} alt="hero slider" />
          <div className={styles.heroContainer}>
            <div className={styles.heroInner}>
                {logo}
                <h3>DAO Launchpad</h3>
            </div>
            <p>The launchpad for real founders</p>
          </div>
      </div>
    );
  }
  
  export default Hero;