// src/Tab.js
import React from 'react';
import styles from './tabs.module.sass'

const Form = () => {
  return (
    <div className={styles.offering}>
        <div className={styles.left}>
          <div className={styles.leftInner}>
            <span className={styles.title}>Closed</span>
            <span className={styles.content}>Ended 2 months ago</span>
          </div>
          <div className={styles.leftInner}>
            <span className={styles.title}>Stakes</span>
            <span className={styles.content}>11,50,000 $YOURAI</span>
            <a href="/" className={styles.content}>form contract</a>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.innerText}>
            <h3>Unlock power of your tokens</h3>
            <p>60 days high-yield farm for YOURAI token. Total yield is 250K YOURAI! Stake now to get yours!</p>
          </div>
          <div className={styles.innerInputs}>
            <div className={styles.deposit}>
              <span>Staked</span>
              <span>0 $YOURAI</span>
            </div>
            <div className={styles.brake}></div>
            <div className={styles.allocation}>
              <span>Yield</span>
              <span>0 $YOURAI</span>
            </div>
          </div>
          <p>Cooldown period is 7 days after requesting unstake. Unstaking tokens during cooldown results in 30% fee.</p>
          <button className={styles.stake} onClick={() => console.log('Bumm')}>Stake</button>
        </div>
    </div>
  );
};

export default Form;