import React from 'react';
import { useState, useEffect } from 'react';
import Form from "./profile/form";
import NotConnected from './profile/notConnected';

const Profile = () => {
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to check if MetaMask is installed and if an account is connected
  const checkWalletConnection = () => {
    const storedAccount = localStorage.getItem('connectedAccount');
    if (storedAccount) {
      setAccount(storedAccount);
    }
    setLoading(false);
  };

  useEffect(() => {
    checkWalletConnection();
    
    // Optionally, you can also listen for account changes here
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length === 0) {
          setAccount(null);
        } else {
          setAccount(accounts[0]);
        }
      });
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', () => {});
      }
    };
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {account ? (
        <Form />
      ) : (
        <NotConnected />
      )}
    </>
  );
};

export default Profile;
