import slider from '../../../images/x-banner.webp'
import styles from './featured.module.sass'
import project from '../../../images/project1.webp'
import { Link } from 'react-router-dom';

function Featured({loading, data}) {
  if(loading)
    return(
      <span>Loading...</span>
    )
  if(!loading)
    return (
      <Link to={data ? `/project/${data[0].id}` : ''} className={styles.featured}>
         <h2>Featured Offerings</h2>
         <div className={styles.inner}>
          <div className={styles.tags}>
            <span>Coming soon</span>
            <span>Refundable</span>
          </div>
          {data[0].logo ? <img src={project} alt="New project coming soon" /> : <span className={styles.featuredImagePlaceholder}></span>}
          <div className={styles.tags}>
            <div className={styles.company}>
              <div className={styles.imagePlaceholder}></div>
              <h4>{data ? data[0].name : null }</h4>
            </div>
            <div></div>
          </div>
          <div className={styles.shadow}></div>
         </div>
      </Link>
    );
  }
  
  export default Featured;