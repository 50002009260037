import React, { useState, useEffect } from 'react';
import styles from './tabs.module.sass';
import Web3 from 'web3';
import usdtAbi from '../../../config/usdtAbi.json';

const leftArrow = <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" className="-ml-2.5 h-7 w-7" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z" fill="#0caf60"></path></svg>;
const rightArrow = <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" className="-mr-1.5 h-7 w-7" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z" fill="#0caf60"></path></svg>;

const Offering = () => {
  const [activeTab, setActiveTab] = useState('USDT'); // State to track the active tab
  const [participated, setParticipated] = useState(false); // New state to track participation
  const [inputValue, setInputValue] = useState(''); // Track input value


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsdtBalance = async () => {
      try {
        if (!window.ethereum) {
          throw new Error('MetaMask is not installed.');
        }

        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts'   
 });    console.log('Accs: ', accounts)
        const userAddress = accounts[0];

        const web3 = new Web3(window.ethereum);   


        // USDT contract address (replace with the actual address)
        const usdtContractAddress = "0xdac17f958d2ee523a2206206994597c13d831c28";

        // Create a contract instance
        const usdtContract = new web3.eth.Contract(usdtAbi, usdtContractAddress);
        
        // Estimate gas for the balanceOf function
        const gasEstimate = await usdtContract.methods.balanceOf(userAddress).estimateGas({ from: userAddress });
        console.log('Gas: ', gasEstimate)
        // Get the user's USDT balance with sufficient gas
        const balance = await usdtContract.methods.balanceOf(userAddress).call({ from: userAddress, gas: gasEstimate + 10000 });

        console.log('Balance: ', balance)

        // Convert Wei to USDT

        const usdtBalance = web3.utils.fromWei(balance, "ether");
        console.log('USDT: ', usdtBalance)
        setBalance(usdtBalance);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUsdtBalance();
  }, []);




  return (
    <>
      <div className={styles.offeringHeader}>
        {/* USDT Round Button */}
        <button
          className={`${styles.offeringHeaderButton} ${activeTab === 'USDT' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('USDT')}
        >
          {leftArrow} USDT Round
        </button>

        {/* DAO Round Button */}
        <button
          className={`${styles.offeringHeaderButton} ${activeTab === 'DAO' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('DAO')}
        >
          DAO Round {rightArrow}
        </button>
      </div>

      <div className={styles.offeringBody}>
        {/* Conditionally Render Tab 1 (USDT Round) */}
        {activeTab === 'USDT' && !participated && (
          <div className={styles.tab1}>
            <div className={styles.offering}>
              <div className={styles.left}>
                <div className={styles.leftInner}>
                  <span className={styles.title}>Closed</span>
                  <span className={styles.content}>Ended 6 months ago</span>
                </div>
                <div className={styles.leftInner}>
                  <span className={styles.title}>Total allocation</span>
                  <span className={styles.content}>50,000 $USDT</span>
                </div>
                <div className={styles.leftInner}>
                  <span className={styles.title}>Deposits</span>
                  <span className={styles.content}>11,50,000 $USDT</span>
                  <span className={styles.content}>231.8x subscribed</span>
                  <a href="/" className={styles.content}>deposit contract</a>
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.innerText}>
                  <h3>Participate in Public round</h3>
                  <span className={styles.green}>Open to everyone.</span>
                  <p>Depositing more grants more allocation.</p>
                </div>
                <div className={styles.innerInputs}>
                  <div className={styles.deposit}>
                    <span>Deposit</span>
                    <span>0 $USDT</span>
                  </div>
                  <div className={styles.brake}></div>
                  <div className={styles.allocation}>
                    <span>Allocation</span>
                    <span>0 $USDT</span>
                  </div>
                </div>
                <button className={styles.participateButton} onClick={() => setParticipated(true)}>Participate</button>
              </div>
            </div>
          </div>
        )}

        {/* Render different content after participation */}
        {activeTab === 'USDT' && participated && (
        <div className={styles.tab1}>
        <div className={styles.offering}>
          <div className={styles.left}>
            <div className={styles.leftInner}>
              <span className={styles.title}>open</span>
              <span className={styles.content}>Ended 6 months ago</span>
            </div>
            <div className={styles.leftInner}>
              <span className={styles.title}>Total allocation</span>
              <span className={styles.content}>50,000 $USDT</span>
            </div>
            <div className={styles.leftInner}>
              <span className={styles.title}>Deposits</span>
              <span className={styles.content}>11,50,000 $USDT</span>
              <span className={styles.content}>231.8x subscribed</span>
              <a href="/" className={styles.content}>deposit contract</a>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.innerInputs}>
              <div className={styles.inputWrapper}>
                <span>Amount in $USDT</span>
                <input value={inputValue}
                      onChange={handleInputChange} type="number" />
                <span>Available: {balance} $USDT</span>
              </div>
            </div>
            <div className={styles.buttonsWrap}>
              <button style={{
                      backgroundColor: inputValue ? '#0caf60' : null, // Change to green if input is not empty
                      color: inputValue ? '#fff' : null, // Optional: change text color
                    }} onClick={() => console.log('bumbastic')}>Approve</button>
              <button className={styles.cancelButton} onClick={() => setParticipated(false)}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
        )}

        {/* Conditionally Render Tab 2 (DAO Round) */}
        {activeTab === 'DAO' && (
          <div className={styles.tab2}>
            <div className={styles.offering}>
              <div className={styles.left}>
                <div className={styles.leftInner}>
                  <span className={styles.title}>Open</span>
                  <span className={styles.content}>Ends in 2 months</span>
                </div>
                <div className={styles.leftInner}>
                  <span className={styles.title}>Total allocation</span>
                  <span className={styles.content}>30,000 $DAO</span>
                </div>
                <div className={styles.leftInner}>
                  <span className={styles.title}>Deposits</span>
                  <span className={styles.content}>8,00,000 $DAO</span>
                  <span className={styles.content}>150x subscribed</span>
                  <a href="/" className={styles.content}>deposit contract</a>
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.innerText}>
                  <h3>Participate in DAO round</h3>
                  <span className={styles.green}>Exclusive to DAO holders.</span>
                  <p>Deposit your DAO tokens for more allocation.</p>
                </div>
                <div className={styles.innerInputs}>
                  <div className={styles.deposit}>
                    <span>Deposit</span>
                    <span>0 $DAO</span>
                  </div>
                  <div className={styles.brake}></div>
                  <div className={styles.allocation}>
                    <span>Allocation</span>
                    <span>0 $DAO</span>
                  </div>
                </div>
                <button onClick={() => console.log('Participate in DAO round')}>Participate</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Offering;
