import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Hero from "./hero/hero";
import Content from "./tabs/content";

function Project() {
  const { projectId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/public/launchpad/${projectId}/`);
        setData(response.data);
        setLoading(false);
      } catch (err) {
        console.log('Get this: ', err)
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [apiUrl, projectId]);

  return (
    <>
        <Hero data={data} loading={loading} error={error} />
        <Content data={data} loading={loading} error={error} />
    </>
  );
}

export default Project;