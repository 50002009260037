import { Link } from "react-router-dom";
import styles from "./sidebar.module.sass";
import MetaMaskComponent from "../wallet/metamask";

function Sidebar({ sidebar, setSidebar }) {
  // Close the sidebar when clicking outside of sidebarInner
  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      setSidebar(false);
    }
  };

  return (
    <div
      className={`${styles.sidebar} ${sidebar ? styles.open : ''}`}
      onClick={handleOutsideClick}
    >
      <div
        className={`${styles.sidebarInner} ${sidebar ? styles.open : ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        <MetaMaskComponent />
        <ul>
          <li><Link onClick={() => setSidebar(false)} to="/profile">Profile</Link></li>
          <li><Link onClick={() => setSidebar(false)} to="/participations">Participations</Link></li>
          <li><Link onClick={() => setSidebar(false)} to="/staking">Staking</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
