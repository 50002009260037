import Info from "./info/info";
import Content from "./tabs/content";

function Staking() {
  return (
    <>
        <Info />
        <Content />
    </>
  );
}

export default Staking;