import React, { useState, useEffect } from "react";
import styles from './metamask.module.sass';
import { ethers } from "ethers";

const disconnectIcon = <svg stroke="currentColor" fill="white" strokeWidth="0" viewBox="0 0 24 24" className="h-6 w-6 cursor-pointer" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M6.26489 3.80698L7.41191 5.44558C5.34875 6.89247 4 9.28873 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 9.28873 18.6512 6.89247 16.5881 5.44558L17.7351 3.80698C20.3141 5.61559 22 8.61091 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 8.61091 3.68594 5.61559 6.26489 3.80698ZM11 12V2H13V12H11Z"></path></svg>

const MetaMaskComponent = () => {
  const [account, setAccount] = useState(null);
  const [error, setError] = useState(null);

  // Function to check if MetaMask is installed
  const checkIfMetaMaskIsInstalled = () => {
    if (typeof window.ethereum !== "undefined") {
      console.log("MetaMask is installed!");
      return true;
    } else {
      console.error("MetaMask is not installed!");
      setError("MetaMask is not installed!");
      return false;
    }
  };

  // Function to connect MetaMask
  const connectMetaMask = async () => {
    if (checkIfMetaMaskIsInstalled()) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const connectedAccount = accounts[0];
        setAccount(connectedAccount);
        localStorage.setItem('connectedAccount', connectedAccount); // Save to localStorage
        console.log("Connected account:", connectedAccount);
      } catch (err) {
        console.error(err);
        setError("Failed to connect to MetaMask");
      }
    }
  };

  // Function to disconnect MetaMask
  const disconnectMetaMask = () => {
    setAccount(null);
    localStorage.removeItem('connectedAccount'); // Remove from localStorage
    console.log("Disconnected account");
  };

  // Function to handle account changes
  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      console.log("Please connect to MetaMask.");
      setAccount(null); // Clear account when disconnected
      localStorage.removeItem('connectedAccount'); // Remove from localStorage
    } else if (accounts[0] !== account) {
      setAccount(accounts[0]);
      localStorage.setItem('connectedAccount', accounts[0]); // Update localStorage
      console.log("Account changed:", accounts[0]);
    }
  };

  // Check for previously connected account on component mount
  useEffect(() => {
    const storedAccount = localStorage.getItem('connectedAccount');
    if (storedAccount) {
      setAccount(storedAccount);
    }

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    // Cleanup listener on component unmount
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, []);

  // Function to format account address
  const formatAccount = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <div className={styles.metamask}>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {account ? (
        <div className={styles.accountContainer}>
          <p>{formatAccount(account)}</p>
          <button onClick={disconnectMetaMask} className={styles.disconnectButton}>{disconnectIcon}</button>
        </div>
      ) : (
        <button className={styles.connectButton} onClick={connectMetaMask}>Connect Wallet</button>
      )}
    </div>
  );
};

export default MetaMaskComponent;
