import styles from './info.module.sass'

function Info() {
    return (
      <div className={styles.info}>
         <h1>Stake</h1>
         <p></p>
      </div>
    );
  }
  
  export default Info;