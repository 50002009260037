// src/App.js
import React from 'react';
import Tabs from './Tabs';
import Tab from './Tab';
import Offering from './offering';
import Form from './form';

function Content() {
  return (
    <div className="App">
      <Tabs>
        <Tab label="Review">
        <h2>What is Step App (FITFI)?</h2>
        <p>Step App is the next joint frontier in the metaverse and GameFi market, and an embodiment of physical experiences tied with the metaverse. </p>

        <p>Fitness and even simple body activity are part of many people’s lives. A blockchain product that taps into something that is a daily part of many people’s lives is at the peak of crypto adoption. </p>

        <p>Get Step App to turn your fitness into a social experience that pays you. Build your step count by walking, jogging, or running to earn. Compete with friends or strangers, socialize locally or globally, and experience the metaverse and your physical world in a dynamic new way.</p>
        <br/>
        <br/>
        <h2>What is Step App (FITFI) roadmap?</h2>
        <p>Pre-registered and new users alike can be excited throughout all of 2022, as the Step App blockchain fitness metaverse is developed and launched later in the year.</p>

        <p>Take part in the beginnings of the FitFi revolution - a world-wide community joining forces to improve their health and help each other achieve.</p>
        <h2>What is Step App (FITFI)?</h2>
        <p>Step App is the next joint frontier in the metaverse and GameFi market, and an embodiment of physical experiences tied with the metaverse. </p>

        <p>Fitness and even simple body activity are part of many people’s lives. A blockchain product that taps into something that is a daily part of many people’s lives is at the peak of crypto adoption. </p>

        <p>Get Step App to turn your fitness into a social experience that pays you. Build your step count by walking, jogging, or running to earn. Compete with friends or strangers, socialize locally or globally, and experience the metaverse and your physical world in a dynamic new way.</p>
        <br/>
        <br/>
        <h2>What is Step App (FITFI) roadmap?</h2>
        <p>Pre-registered and new users alike can be excited throughout all of 2022, as the Step App blockchain fitness metaverse is developed and launched later in the year.</p>

        <p>Take part in the beginnings of the FitFi revolution - a world-wide community joining forces to improve their health and help each other achieve.</p>
        <h2>What is Step App (FITFI)?</h2>
        <p>Step App is the next joint frontier in the metaverse and GameFi market, and an embodiment of physical experiences tied with the metaverse. </p>

        <p>Fitness and even simple body activity are part of many people’s lives. A blockchain product that taps into something that is a daily part of many people’s lives is at the peak of crypto adoption. </p>

        <p>Get Step App to turn your fitness into a social experience that pays you. Build your step count by walking, jogging, or running to earn. Compete with friends or strangers, socialize locally or globally, and experience the metaverse and your physical world in a dynamic new way.</p>
        <br/>
        <br/>
        <h2>What is Step App (FITFI) roadmap?</h2>
        <p>Pre-registered and new users alike can be excited throughout all of 2022, as the Step App blockchain fitness metaverse is developed and launched later in the year.</p>

        <p>Take part in the beginnings of the FitFi revolution - a world-wide community joining forces to improve their health and help each other achieve.</p>
        </Tab>
        <Tab label="Offering">
          <Offering />
        </Tab>
        <Tab label="Form">
          <Form />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Content;
