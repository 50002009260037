import './styles/app.sass';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Page from './components/page';
import Home from './pages/home'
import Apply from './pages/apply';
import Project from './pages/project';
import Profile from './pages/profile';
import Participations from './pages/participations';
import Staking from './pages/staking';

function App({ pageProps }) {
  return (
<Router {...pageProps}>
      <Routes>
        <Route 
          path="/" 
          element={
            <Page>
              <Home />
            </Page>
          }
        />
        <Route 
          path="/profile" 
          element={
            <Page>
                <Profile />
            </Page>
          }
        />
        <Route 
          path="/participations" 
          element={
            <Page>
                <Participations />
            </Page>
          }
        />
        <Route 
          path="/staking" 
          element={
            <Page>
                <Staking />
            </Page>
          }
        />
         <Route 
          path="/project/:projectId" 
          element={
            <Page>
                <Project />
            </Page>
          }
        />
        <Route 
          path="/apply" 
          element={
            <Page>
                <Apply />
            </Page>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
