import React, { useState, useEffect } from 'react';
import styles from './tabs.module.sass';

const Stake = () => {
  const [account, setAccount] = useState(null);

  // Function to check if MetaMask is installed and if an account is connected
  const checkWalletConnection = () => {
    const storedAccount = localStorage.getItem('connectedAccount');
    if (storedAccount) {
      setAccount(storedAccount);
    }
  };

  // Function to connect MetaMask
  const connectMetaMask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const connectedAccount = accounts[0];
        setAccount(connectedAccount);
        localStorage.setItem('connectedAccount', connectedAccount); // Save to localStorage
      } catch (err) {
        console.error('Failed to connect to MetaMask:', err);
      }
    } else {
      console.error('MetaMask is not installed');
    }
  };

  // Function to handle account changes
  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      setAccount(null);
      localStorage.removeItem('connectedAccount'); // Remove from localStorage
    } else {
      setAccount(accounts[0]);
      localStorage.setItem('connectedAccount', accounts[0]); // Update localStorage
    }
  };

  // Check wallet connection on component mount
  useEffect(() => {
    checkWalletConnection();

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    // Cleanup listener on component unmount
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, []);

  return (
    <div className={styles.offering}>
      <div className={styles.right}>
        <div className={styles.inputInner}>
          <span>Amount</span>
          <input type="number" />
          <span>Available: 0 $DAO</span>
        </div>
        <div className={styles.inputInner}>
          <div className={styles.inputLabelWrapper}>
            <span>Days</span>
            <span>Min | Max</span>
          </div>
          <input type="number" />
          <span>APR Bonus: N/A</span>
        </div>
        {account ? (
          <button className={styles.stake} onClick={() => console.log('Staking...')}>Stake</button>
        ) : (
          <button className={styles.connectWallet} onClick={connectMetaMask}>Connect Wallet</button>
        )}
      </div>
    </div>
  );
};

export default Stake;
